import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { BaseWhiteBg } from "../components/BaseWhiteBg";
import { H2 } from "../components/H2";
import { Loading } from "../components/Loading";
import { Space } from "../components/Space";
import { getEmail } from "../firebase/index";
import { useAuthUser } from "../hooks/useAuth";
const title = "お問い合わせ";

const Contact = () => {
  const [isLoading, setIsLoading] = useState(true);
  const user = useAuthUser();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [uid, setUid] = useState("");
  const [isTallyLoaded, setIsTallyLoaded] = useState(false);
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);

  useEffect(() => {
    const widgetScriptSrc = "https://tally.so/widgets/embed.js";

    const load = () => {
      if (typeof (window as any).Tally !== "undefined") {
        (window as any).Tally.loadEmbeds();
      } else {
        document
          .querySelectorAll("iframe[data-tally-src]:not([src])")
          .forEach((iframeEl) => {
            const iframe = iframeEl as HTMLIFrameElement;
            iframe.src = iframe.getAttribute("data-tally-src")!;
          });
      }
      setIsTallyLoaded(true);
    };

    if (typeof (window as any).Tally !== "undefined") {
      load();
    } else if (
      document.querySelector(`script[src="${widgetScriptSrc}"]`) === null
    ) {
      const script = document.createElement("script");
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      if (user === null) {
        navigate("/login?from=contact");
      } else {
        const uidData = user.uid;
        const emailData = await getEmail(uidData);
        if (emailData) {
          setEmail(emailData.email || "");
          setUid(uidData || "");
        } else {
          setEmail("");
          setUid("");
        }
      }
      setIsUserDataLoaded(true);
    };
    fetchUser();
  }, [user, navigate]);

  useEffect(() => {
    if (isTallyLoaded && isUserDataLoaded) {
      setIsLoading(false);
    }
  }, [isTallyLoaded, isUserDataLoaded]);

  const iframeSrc = `https://tally.so/embed/w5jWZP?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1&email=${encodeURIComponent(
    email
  )}&uid=${encodeURIComponent(uid)}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <BaseWhiteBg>
        <H2 text={title} blue={3} />
        {isLoading ? (
          <>
            <Space height={64} />
            <Loading topColor="#666" borderColor="rgba(0, 0, 0, 0.3)" />
            <Space height={64} />
          </>
        ) : (
          <iframe
            src={iframeSrc}
            loading="lazy"
            width="100%"
            height={804}
            title="【原画くじ】お問い合わせフォーム"
            style={{ border: "none", overflow: "hidden" }}
          ></iframe>
        )}
      </BaseWhiteBg>
    </>
  );
};

export default Contact;
