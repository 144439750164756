import { logEvent } from "firebase/analytics";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useProductId } from "../context/index";
import { analytics } from "../firebase";
import { useAuthUser } from "../hooks/useAuth";

const userLinks = [
  {
    to: "/shipping",
    src: require("../assets/icon/shipping.webp"),
    alt: "配送管理ロゴ",
    text: "配送管理",
  },
  {
    to: "/collection",
    src: require("../assets/icon/collection.webp"),
    alt: "コレクションロゴ",
    text: "コレクション",
  },
  {
    to: "/mypage",
    src: require("../assets/icon/mypage.webp"),
    alt: "マイページロゴ",
    text: "マイページ",
  },
];

export const Header = () => {
  const [isLogin, setIsLogin] = useState(false);
  const user = useAuthUser();
  const { productId } = useProductId();
  const registerLink = productId ? `/register?from=${productId}` : "/register";

  useEffect(() => {
    setIsLogin(!!user?.uid);
  }, [user]);

  const toRegister = () => {
    logEvent(
      analytics,
      `登録・ログイン${window.location.pathname + window.location.search}`
    );
  };

  return (
    <header className="fixed inset-x-0 top-0 z-[1000] bg-[rgba(255,255,255,0.1)] shadow-md backdrop-blur-[2px]">
      <div className="mx-auto flex h-14 max-w-[720px] items-center justify-between p-4">
        <div className="flex h-full w-[35%] shrink-0 items-center">
          <Link to="/" className="inline-block h-auto w-full">
            <img
              src={require("../assets/logo/header-logo.webp")}
              alt="ロゴ"
              className="h-auto max-w-full align-middle"
            />
          </Link>
        </div>
        {isLogin ? (
          <div className="flex h-full w-[65%] items-center justify-end">
            {userLinks.map((link, index) => (
              <div key={index} className="ml-2 flex flex-col items-center">
                <Link
                  to={link.to}
                  className="flex flex-col items-center text-black no-underline"
                >
                  <img
                    src={link.src}
                    alt={link.alt}
                    className="mb-1 max-h-[28px] w-auto"
                  />
                  <p className="text-[8px]">{link.text}</p>
                </Link>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex h-full items-center">
            <Link to={registerLink} onClick={toRegister}>
              <button className="h-10 cursor-pointer rounded-lg border-none bg-black p-2.5 text-sm text-white">
                登録・ログイン
              </button>
            </Link>
          </div>
        )}
      </div>
    </header>
  );
};
