export const formatZipCode = (zip: number | string): string => {
  // 入力値がnullまたはundefinedの場合、空の文字列を返す
  if (zip == null) {
    return "";
  }

  // 数値を文字列に変換し、数字以外の文字を取り除く
  const numbersOnly = zip.toString().replace(/[^0-9]/g, "");

  // 3桁と4桁に分ける
  if (numbersOnly.length <= 3) {
    // 3桁以下の場合はそのまま返す
    return numbersOnly;
  } else {
    // 3桁と4桁に分けてハイフンを挟む
    return numbersOnly.slice(0, 3) + "-" + numbersOnly.slice(3);
  }
};
