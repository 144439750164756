import { memo } from "react";

interface Props {
  text: string;
}

export const BgH2 = memo(({ text }: Props) => {
  return (
    <div className="flex items-center justify-center">
      <h2 className="flex h-[60px] w-[209.47px] items-center justify-center bg-[url('../src/assets/titile/h2-title.webp')] bg-cover bg-center">
        {text}
      </h2>
    </div>
  );
});
