import { memo } from "react";

import { AvailableShipInfo } from "../../pages/Shipping";
import {
  convertScheduledShippingAt,
  convertshippingRequestDeadline,
} from "../../utils/index";
import { BaseButton } from "../Button/BaseButton";
import { Img } from "../Img";
import { Space } from "../Space";

import { BaseCard } from "./BaseCard";

interface Props {
  availableShipInfo: AvailableShipInfo;
  handleShowModal: () => void; // ボタンクリック時のコールバックを追加
  chooseOneProduct: (item: AvailableShipInfo) => void;
}

export const RequestCard = memo(
  ({ availableShipInfo, handleShowModal, chooseOneProduct }: Props) => {
    const {
      productId,
      productName,
      numOfAvailableDeliveries,
      shippingRequestDeadline,
      scheduledShippingAt,
      postage,
    } = availableShipInfo;

    const handleRequestClick = () => {
      chooseOneProduct(availableShipInfo);
      handleShowModal();
    };

    return (
      <BaseCard>
        <Space height={16} />
        <Img imgPath={`${productId}/ogp.png`} alt="商品画像" />
        <Space height={16} />
        <h3>{productName}</h3>
        <Space height={8} />
        <p>全{numOfAvailableDeliveries}点</p>
        <Space height={16} />
        <div className="text-left">
          <label>配送依頼期限</label>
          <p> {convertshippingRequestDeadline(shippingRequestDeadline)}</p>
          <Space height={16} />
          <label>発送予定時期</label>
          <p>{convertScheduledShippingAt(scheduledShippingAt)}</p>
          <Space height={16} />
          <label>送料・梱包費</label>
          <p>{postage}円（税込）</p>
          <p className="text-xs text-[#999999]">
            ※配送依頼ごとに全国一律の料金です。
          </p>
        </div>
        <Space height={16} />
        <BaseButton onClick={handleRequestClick} title="配送依頼へ進む" />
        <Space height={16} />
      </BaseCard>
    );
  }
);
