import { Gender, UserType } from "@gengakuji/common";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";

import { db } from "../firebase";

const getUserPath = (uid: string) => {
  return doc(db, "users", uid);
};

export const getUser = async (uid: string) => {
  const userSnap = await getDoc(getUserPath(uid));

  if (userSnap.exists()) {
    return { ...userSnap.data() } as UserType;
  } else {
    return null;
  }
};

export const updateGender = async (uid: string, gender: Gender) => {
  await updateDoc(getUserPath(uid), {
    gender,
  });
};

export const updateBirth = async (uid: string, birthDate: Timestamp) => {
  await updateDoc(getUserPath(uid), {
    birthDate,
  });
};

export const updateLastLoginedAt = async (uid: string) => {
  await updateDoc(getUserPath(uid), {
    lastLoginedAt: serverTimestamp(),
  });
};
