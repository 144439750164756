import { Space } from "./Space";

const links = [
  { href: "https://avaren.co.jp/gengakuji-terms", text: "利用規約" },
  {
    href: "https://avaren.co.jp/gengakuji-privacy",
    text: "プライバシーポリシー",
  },
  { href: "https://avaren.co.jp/gengakuji-company", text: "運営会社" },
  { href: "https://avaren.co.jp/gengakuji-scta", text: "特定商取引法の記載" },
  { href: "https://avaren.co.jp/gengakuji-help", text: "よくある質問" },
  { href: "/contact", text: "お問い合せ" }, // 別タブで開かない
  { href: "https://twitter.com/gengakuji", text: "X（旧Twitter）" },
];

export const Footer = () => {
  return (
    <div className="bg-green">
      <Space height={16} />
      <div className="mx-auto max-w-[720px] p-8 text-center">
        <img
          src={require("../assets/logo/logo.webp")}
          alt="ロゴ"
          width={"100%"}
        />
        <Space height={32} />
        <div className="text-left">
          {links.map((link, index) => (
            <div key={index}>
              <a
                href={link.href}
                className="text-sm text-black no-underline"
                target={link.text === "お問い合せ" ? "_self" : "_blank"} // お問い合わせリンクだけは同じタブで開く
                rel={
                  link.text === "お問い合せ" ? undefined : "noopener noreferrer"
                }
              >
                {link.text}
              </a>
              <Space height={12} />
            </div>
          ))}
        </div>
        <Space height={32} />
        <img
          src={require("../assets/logo/footer-logo.webp")}
          alt="ロゴ"
          width={"100%"}
        />
        <Space height={32} />
        <p>(C) GENGAKUJI</p>
        <Space height={128} />
      </div>
    </div>
  );
};
