import { ApplyGengaInfoType, ApplyShipInfoType, GengaInfoType } from "@gengakuji/common";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  Timestamp,
} from "firebase/firestore";

import { db } from "../firebase";

//ユーザーがWチャンスに応募した原画情報のみ取得
export const getApplyGengaInfo: (
  productId: string,
  uid: string
) => Promise<ApplyGengaInfoType[]> = async (productId: string, uid: string) => {
  const userApplyDocRef = doc(db, "wchance", uid, "apply", productId);
  const docSnapshot = await getDoc(userApplyDocRef);
  if (!docSnapshot.exists()) {
    console.log("ドキュメントが存在しません。");
    return [];
  }
  const applyGengaInfo = docSnapshot.data() as ApplyGengaInfoType;
  return [applyGengaInfo];
};

export const getApplyShipInfo: (
  productId: string,
  uid: string
) => Promise<ApplyShipInfoType | null> = async (
  productId: string,
  uid: string
) => {
  const userApplyDocRef = doc(db, "wchance", uid, "apply", productId);
  const docSnapshot = await getDoc(userApplyDocRef);

  if (!docSnapshot.exists()) {
    return null;
  }

  const applyData = docSnapshot.data() as ApplyGengaInfoType;
  const applyShipInfo: ApplyShipInfoType = {
    firstName: applyData.shipInfo.firstName,
    lastName: applyData.shipInfo.lastName,
    sei: applyData.shipInfo.sei,
    mei: applyData.shipInfo.mei,
    phone: applyData.shipInfo.phone,
    zip: applyData.shipInfo.zip,
    prefecture: applyData.shipInfo.prefecture,
    city: applyData.shipInfo.city,
    address: applyData.shipInfo.address,
    building: applyData.shipInfo.building,
    productId: applyData.productId,
  };

  return applyShipInfo;
};

export const applyWChance = async (
  productId: string,
  user: { uid: string },
  gengaInfo: GengaInfoType[],
  ticketCounts: { [key: number]: number },
  shipInfo: ApplyShipInfoType
) => {
  const db = getFirestore();
  const applyDocRef = doc(db, "wchance", user.uid, "apply", productId);

  let createdAt = Timestamp.now(); // デフォルトの値を現在のタイムスタンプに設定

  // 既存のデータを確認
  const docSnap = await getDoc(applyDocRef);
  if (docSnap.exists()) {
    const existingData = docSnap.data() as ApplyGengaInfoType;
    createdAt = existingData.createdAt; // 既存のcreatedAtを保持
  }

  const applyShipInfo: ApplyShipInfoType = {
    ...shipInfo,
    productId: productId,
  };

  const applyGengaInfo: ApplyGengaInfoType = {
    productId: productId,
    uid: user.uid,
    items: gengaInfo
      .filter((genga) => ticketCounts[genga.index] > 0)
      .map((genga) => ({
        index: genga.index,
        displayName: genga.displayName,
        productId: genga.productId,
        applyCount: ticketCounts[genga.index],
      })),
    createdAt,
    updatedAt: Timestamp.now(),
    shipInfo: applyShipInfo,
  };

  try {
    await setDoc(applyDocRef, applyGengaInfo);
    return true;
  } catch (error) {
    console.error("Firestore saving error: ", error);
    return false;
  }
};
