import { memo } from "react";
import { Link } from "react-router-dom";

import { Img } from "../Img";
import { Space } from "../Space";

import { BaseCard } from "./BaseCard";

interface Props {
  productId: string;
  productName: string;
  count: number;
  haveCount: number;
}

export const CollectionCard = memo(
  ({ productId, productName, count, haveCount }: Props) => {
    return (
      <Link to={productId}>
        <BaseCard>
          <Space height={24} />
          <Img imgPath={`${productId}/ogp.png`} alt="商品画像" />
          <Space height={20} />
          <h3 className="text-base text-black">{productName}</h3>
          <Space height={20} />
          <span className="text-base text-black">
            所持数：{haveCount}/{count}
          </span>
          <Space height={24} />
        </BaseCard>
      </Link>
    );
  }
);
