import { memo, useState } from "react";

import { getUserIdToken } from "../../firebase/index";
import { AvailableShipInfo } from "../../pages/Shipping";
import { AddressForm } from "../AddressForm";
import { BaseButton } from "../Button/BaseButton";
import { DisabledButton } from "../Button/DisabledButton";
import { ColorSpan } from "../ColorSpan";
import { H2 } from "../H2";
import { Img } from "../Img";
import { Space } from "../Space";

import { BaseModal } from "./BaseModal";

interface Props {
  productData: AvailableShipInfo | null;
  onClose: () => void;
}

export const RequestModal = memo(({ productData, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sei, setSei] = useState("");
  const [mei, setMei] = useState("");
  const [phone, setPhone] = useState("");
  const [zip, setZip] = useState("");
  const [prefecture, setPrefecture] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [building, setBuilding] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [seiError, setSeiError] = useState("");
  const [meiError, setMeiError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [zipError, setZipError] = useState("");

  // バリデーションまとめて判定
  const isFormValid =
    firstName &&
    !firstNameError &&
    lastName &&
    !lastNameError &&
    sei &&
    !seiError &&
    mei &&
    !meiError &&
    phone &&
    !phoneError &&
    zip &&
    !zipError &&
    address;

  // 配送依頼ボタンを押したときの処理
  const handleSubmit = async () => {
    setIsLoading(true);

    if (!productData) {
      setIsLoading(false);
      return;
    }

    const idToken = await getUserIdToken();

    const body = {
      firstName,
      lastName,
      sei,
      mei,
      phone,
      zip,
      prefecture,
      city,
      address,
      building,
      productId: productData.productId,
      priceId: productData.priceIds.postage,
      event: "REQUEST_SHIP",
    };

    const res = await fetch(
      process.env.REACT_APP_CREATE_REQUEST_SHIP_CHECKOUT_SESSION!,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    const data = await res.json();
    window.location.replace(data.redirectURL);
    setIsLoading(false);
  };

  return (
    <>
      <BaseModal bgColor="bg-white" showCross={false}>
        <H2 text="配送依頼" blue={3} />
        <>
          <Img imgPath={`${productData?.productId}/ogp.png`} alt="商品画像" />
          <Space height={16} />
          <h3>{productData?.productName}</h3>
          <p>全{productData?.numOfAvailableDeliveries}点</p>
        </>

        <Space height={16} />

        <>
          {/* ここから商品を表示 */}
          {productData?.items && productData.items.length > 0 && (
            <div className="mt-4 space-y-4">
              {productData.items.map((item) => (
                <div
                  key={item.cutId}
                  className="flex items-center justify-between"
                >
                  {/* 左側の画像ブロック */}
                  <div className="w-1/2">
                    <Img
                      imgPath={`${productData.productId}/cover/${item.index}.png`}
                      alt={item.name}
                      className="w-full shadow-md"
                    />
                  </div>

                  {/* 右側の情報ブロック */}
                  <div className="w-1/2 pl-4">
                    <p className="text-left text-base">{item.name}</p>
                    <p className="text-left text-base">数量: {item.amount}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
          {/* ここまで商品を表示 */}
        </>

        <Space height={32} />

        <>
          <AddressForm
            //
            firstName={firstName}
            lastName={lastName}
            sei={sei}
            mei={mei}
            phone={phone}
            zip={zip}
            prefecture={prefecture}
            city={city}
            address={address}
            building={building}
            //
            firstNameError={firstNameError}
            lastNameError={lastNameError}
            seiError={seiError}
            meiError={meiError}
            phoneError={phoneError}
            zipError={zipError}
            //
            setFirstName={setFirstName}
            setLastName={setLastName}
            setSei={setSei}
            setMei={setMei}
            setPhone={setPhone}
            setZip={setZip}
            setPrefecture={setPrefecture}
            setCity={setCity}
            setAddress={setAddress}
            setBuilding={setBuilding}
            //
            setFirstNameError={setFirstNameError}
            setLastNameError={setLastNameError}
            setSeiError={setSeiError}
            setMeiError={setMeiError}
            setPhoneError={setPhoneError}
            setZipError={setZipError}
            //
            isReadOnly={false}
          />
          <div className="text-left">
            <label>送料・梱包費</label>
            <p>{productData?.postage}円（税込）</p>
          </div>
          <Space height={44} />
        </>
        <>
          {isFormValid ? (
            <BaseButton
              title="配送依頼する"
              backgroundColor="#ff4127"
              textColor="#fff"
              onClick={handleSubmit}
              isLoading={isLoading}
            />
          ) : (
            <DisabledButton title="配送依頼する" />
          )}
          <Space height={8} />
          <p className="text-xs text-[#999999]">決済画面に遷移します。</p>
        </>
        <Space height={44} />
        <div onClick={onClose}>
          <ColorSpan>配送管理へ戻る</ColorSpan>
        </div>
        <Space height={44} />
      </BaseModal>
    </>
  );
});
