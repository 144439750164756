import { RequestedShipInfo, ShippedInfo } from "@gengakuji/common";

import { AvailableShipInfo } from "../pages/Shipping";

import { RequestCard } from "./Card/RequestCard";
import { RequestedCard } from "./Card/RequestedCard";
import { ShippedCard } from "./Card/ShippedCard";
import { Loading } from "./Loading";
import { Space } from "./Space";

type Props = {
  activeTab: string;
  isLoadingRequest: boolean;
  isLoadingRequested: boolean;
  isLoadingShipped: boolean;
  availableShipInfo: AvailableShipInfo[] | null;
  requestedShipInfo: RequestedShipInfo[] | null;
  shippedInfo: ShippedInfo[] | null;
  setshowModal: (show: boolean) => void;
  chooseOneProduct: (item: AvailableShipInfo) => void;
};

export const TabRenderContent = ({
  activeTab,
  isLoadingRequest,
  isLoadingRequested,
  isLoadingShipped,
  availableShipInfo,
  requestedShipInfo,
  shippedInfo,
  setshowModal,
  chooseOneProduct,
}: Props) => {
  switch (activeTab) {
    case "request":
      if (isLoadingRequest) {
        return <Loading topColor="#666" borderColor="rgba(0, 0, 0, 0.3)" />;
      }
      return (
        <div>
          {availableShipInfo ? (
            availableShipInfo.map((val) => (
              <RequestCard
                key={val.productId}
                availableShipInfo={val}
                handleShowModal={() => setshowModal(true)}
                chooseOneProduct={chooseOneProduct}
              />
            ))
          ) : (
            <>
              <Space height={48} />
              <p>配送依頼可能な商品はありません。</p>
              <Space height={48} />
            </>
          )}
        </div>
      );
    case "requested":
      if (isLoadingRequested) {
        return <Loading topColor="#666" borderColor="rgba(0, 0, 0, 0.3)" />;
      }
      return (
        <div>
          {requestedShipInfo ? (
            requestedShipInfo.map((val) => (
              <RequestedCard key={val.shipInfo.id} requestedShipInfo={val} />
            ))
          ) : (
            <>
              <Space height={48} />
              <p>配送依頼済みの商品はありません。</p>
              <Space height={48} />
            </>
          )}
        </div>
      );
    case "shipped":
      if (isLoadingShipped) {
        return <Loading topColor="#666" borderColor="rgba(0, 0, 0, 0.3)" />;
      }
      return (
        <div>
          {shippedInfo ? (
            shippedInfo.map((val) => (
              <ShippedCard key={val.shipInfo.id} shippedInfo={val} />
            ))
          ) : (
            <>
              <Space height={48} />
              <p>発送済みの商品はありません。</p>
              <Space height={48} />
            </>
          )}
        </div>
      );
    default:
      return null;
  }
};
