import {
  OrderType,
  ProductType,
  RequestedShipInfo,
  ShippedInfo,
} from "@gengakuji/common";
import { Timestamp } from "firebase/firestore";
import { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { BgH2 } from "../components/BgH2";
import { RequestModal } from "../components/Modal/RequestModal";
import { Space } from "../components/Space";
import { Tab } from "../components/Tab";
import { TabRenderContent } from "../components/TabRenderContent";
import { getShipInfo, getShippedInfo, getUser } from "../firebase/index";
import { useAuthUser } from "../hooks/useAuth";

export type AvailableShipInfo = ProductType & {
  numOfAvailableDeliveries: number;
  items: OrderType[]
};

const convertToFirestoreTimestamp = (obj: any) => {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      // オブジェクトがTimestampの形式であるかを確認
      if (
        obj[key].seconds !== undefined &&
        obj[key].nanoseconds !== undefined
      ) {
        obj[key] = new Timestamp(obj[key].seconds, obj[key].nanoseconds);
      } else {
        // 再帰的に子オブジェクトを探索
        convertToFirestoreTimestamp(obj[key]);
      }
    }
  }
};

const title = "配送管理";

const Shipping = memo(() => {
  const [activeTab, setActiveTab] = useState("request");
  const [showModal, setshowModal] = useState(false);

  const [availableShipInfo, setAvailableShipInfo] = useState<
    AvailableShipInfo[] | null
  >(null);
  const [requestShip, setRequestShip] = useState<AvailableShipInfo | null>(
    null
  );
  const [requestedShipInfo, setRequestedShipInfo] = useState<
    RequestedShipInfo[] | null
  >(null);
  const [shippedInfo, setShippedInfo] = useState<ShippedInfo[] | null>(null);

  const [isLoadingRequest, setIsLoadingRequest] = useState(true);
  const [isLoadingRequested, setIsLoadingRequested] = useState(true);
  const [isLoadingShipped, setIsLoadingShipped] = useState(true);

  const user = useAuthUser();

  //配送依頼可能データ取得
  useEffect(() => {
    const fetchNumOfAvailableDelivery = async () => {
      if (user) {
        const userData = await getUser(user.uid);
        if (!userData) return;
        const body = {
          uid: user.uid,
          productIds: userData.boughtProductIds,
        };
        try {
          const res = await fetch(
            process.env.REACT_APP_GET_AVAILABLE_DELIVERIESINFO,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            }
          );

          if (res.status === 204) return;
          const data = await res.json();
          if (data) {
            convertToFirestoreTimestamp(data);
            setAvailableShipInfo(
              data.availableDeliveriesInfo as AvailableShipInfo[]
            );
          }
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoadingRequest(false);
        }
      }
    };
    if (activeTab === "request") {
      fetchNumOfAvailableDelivery();
    }
  }, [activeTab, user]);

  //配送依頼済みデータ取得
  useEffect(() => {
    const fetchRequestedInfo = async () => {
      if (user) {
        const data = await getShipInfo(user.uid);
        if (data.length > 0) {
          setRequestedShipInfo(data);
        }
      }
      setIsLoadingRequested(false);
    };
    if (activeTab === "requested") {
      fetchRequestedInfo();
    }
  }, [activeTab, user]);

  //発送済みデータ取得
  useEffect(() => {
    const fetchShippedInfo = async () => {
      if (user) {
        const data = await getShippedInfo(user.uid);
        if (data.length > 0) {
          setShippedInfo(data);
        }
      }
      setIsLoadingShipped(false);
    };
    if (activeTab === "shipped") {
      fetchShippedInfo();
    }
  }, [activeTab, user]);

  //商品を選択
  const chooseOneProduct = (item: AvailableShipInfo) => {
    if (!availableShipInfo) return;
    setRequestShip(item);
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Space height={24} />
      <BgH2 text={title} />
      <Space height={24} />
      <Tab activeTab={activeTab} onChangeTab={setActiveTab} />
      <div className="flex min-h-[calc(100vh-116px)] justify-center rounded-b-2xl bg-white p-0 px-6 shadow-md">
        <TabRenderContent
          activeTab={activeTab}
          isLoadingRequest={isLoadingRequest}
          isLoadingRequested={isLoadingRequested}
          isLoadingShipped={isLoadingShipped}
          availableShipInfo={availableShipInfo}
          requestedShipInfo={requestedShipInfo}
          shippedInfo={shippedInfo}
          setshowModal={setshowModal}
          chooseOneProduct={chooseOneProduct}
        />
      </div>
      <Space height={16} />
      <div>
        {showModal && (
          <RequestModal
            productData={requestShip}
            onClose={() => setshowModal(false)}
          />
        )}
      </div>
    </>
  );
});

export default Shipping;
