import type { Timestamp } from "firebase/firestore";

export type UserType = {
  uid: string;
  boughtProductIds: string[];
  gender: Gender | null;
  birthDate: Timestamp | null;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  lastLoginedAt: Timestamp;
  stripeId: string;
  stripeLink: string;
};

export const GENDER = {
  MALE: 0,
  FEMALE: 1,
  OTHER: 2,
} as const;

export type Gender = 0 | 1 | 2;
