import { ShippedInfo } from "@gengakuji/common";
import { memo } from "react";
import { useNavigate } from "react-router-dom";

import { convertFormattedDate, formatZipCode } from "../../utils";
import { BaseButton } from "../Button/BaseButton";
import { Img } from "../Img";
import { Space } from "../Space";

import { BaseCard } from "./BaseCard";

interface Props {
  shippedInfo: ShippedInfo;
}

export const ShippedCard = memo(({ shippedInfo }: Props) => {
  const { productId, productName } = shippedInfo.productData;
  const { numOfAvailableShip, shippedAt, customReceiptNumber, trackingNumber } =
    shippedInfo.shipInfo;
  const trackingUrl = `https://trackings.post.japanpost.jp/services/srv/search/direct?reqCodeNo1=${trackingNumber}`;

  const navigate = useNavigate();

  const handleNavigate = () => {
    const customReceiptNumber = shippedInfo.shipInfo.customReceiptNumber;
    navigate(`/shipping/${customReceiptNumber}`);
  };

  return (
    <BaseCard>
      <Space height={16} />
      <>
        <Img imgPath={`${productId}/ogp.png`} alt="商品画像" />
        <Space height={16} />
        <h3>{productName}</h3>
        <Space height={8} />
        <p>全{numOfAvailableShip}点</p>
      </>
      <Space height={16} />
      <div className="text-left">
        <>
          <label>発送日時</label>
          <p>{convertFormattedDate(shippedAt)}</p>
        </>
        <Space height={16} />
        <label>配送業者</label>
        <p>日本郵便</p>
        <Space height={16} />
        <>
          <label>追跡番号</label>
          <p>{trackingNumber}</p>
          <p className="text-xs text-[#999999]">
            ※配送状況は
            <a
              href={trackingUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue underline"
            >
              こちら
            </a>
            から確認可能です。
          </p>
        </>
        <Space height={16} />
        <>
          <label>配送管理番号</label>
          <p>{customReceiptNumber}</p>
        </>
      </div>
      <Space height={16} />
      <BaseButton onClick={handleNavigate} title="詳細を見る" />
      <Space height={16} />
    </BaseCard>
  );
});
