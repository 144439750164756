import { memo } from "react";

import TutorialVideo from "../assets/parapara.webp";

import { Space } from "./Space";

interface Props {
  isOpen?: boolean;
  onClose: () => void;
}

export const TutorialOverlay = memo(({ onClose }: Props) => {
  return (
    <div
      className="fixed inset-0 z-[9999] flex size-full cursor-pointer flex-col items-center justify-center bg-black/80"
      onClick={onClose}
    >
      <img
        alt="動くデジタル原画の説明アニメーション"
        width="100%"
        src={TutorialVideo}
        className="w-auto max-[405px]:w-3/4"
      />
      <p className="text-4xl font-medium text-white max-[405px]:text-2xl">
        縦スクロールで
        <br />
        パラパラ動く！
      </p>
      <Space height={16} />
      <span className="text-base text-[rgba(255,255,255,0.5)]">閉じる</span>
    </div>
  );
});
