import { CollectionType } from "@gengakuji/common";
import { collection, getDocs } from "firebase/firestore";

import { FirestoreDocument } from "../constants";
import { db } from "../firebase";

export const getCollection: (
  uid: string,
  productId: string
) => Promise<FirestoreDocument<CollectionType>[]> = async (
  uid: string,
  productId: string
) => {
  const collections: FirestoreDocument<CollectionType>[] = [];
  // productDataを取得する処理を追加する
  const collectionRef = collection(db, "collections", uid, productId);
  const collectionSnap = await getDocs(collectionRef);
  collectionSnap.forEach((doc) => {
    collections.push({
      id: doc.id,
      ...doc.data(),
    } as FirestoreDocument<CollectionType>);
  });

  return collections;
};
