interface Props {
  borderColor?: string;
  topColor?: string;
}

export const Loading = ({
  borderColor = "rgba(255, 255, 255, 0.3)",
  topColor = "#fff",
}: Props) => {
  return (
    <>
      <style>
        {`
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
      <div
        className="m-1.5 inline-block size-6 rounded-full border-4 border-solid"
        style={{
          borderColor: `${borderColor}`,
          borderTopColor: `${topColor}`,
          animation: "spin 1s linear infinite",
        }}
      />
    </>
  );
};
