import { Space } from "./Space";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  message: string;
  closeButtonText?: string;
}

export const Popup = ({
  isVisible,
  onClose,
  message,
  closeButtonText = "閉じる",
}: Props) => {
  if (!isVisible) {
    return null;
  }

  const handleClickOutside = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.currentTarget === event.target) {
      onClose();
    }
  };

  return (
    <div
      className="fixed left-0 top-0 z-[100000] flex size-full items-center justify-center bg-[rgba(0,0,0,0.5)]"
      onClick={handleClickOutside}
    >
      <div className="m-4 rounded-lg bg-white p-4">
        <p className="whitespace-pre-wrap">{message}</p>
        <Space height={8} />
        <button
          className="cursor-pointer border-none bg-transparent text-xs text-gray-600 hover:text-gray-800"
          onClick={onClose}
        >
          {closeButtonText}
        </button>
      </div>
    </div>
  );
};
