import { Timestamp } from "firebase/firestore";
import { useParams } from "react-router-dom";

import { CircleButton } from "./Button/CircleButton";
import { DisabledCircleButton } from "./Button/DisabledCircleButton";
import { Img } from "./Img";
import { Space } from "./Space";

interface Props {
  index: number;
  displayName: string;
  productId: string;
  applyCount: number;
  restTicketCount?: number;
  updateTicketCount?: (change: number, allChange: number) => void;
  onClick?: () => void;
  end: Timestamp;
}

export const ApplyItem = ({
  index,
  displayName,
  applyCount,
  restTicketCount,
  updateTicketCount,
  onClick,
  end,
}: Props) => {
  const { productId } = useParams<{ productId: string }>();

  const now = new Date();
  //応募期間判定
  const isEnd = now < end.toDate();

  // プラスボタン（チケットを増やす）の表示条件
  const canIncrease = restTicketCount ?? 0 > 0;

  // マイナスボタン（チケットを減らす）の表示条件
  const canDecrease = applyCount > 0;

  return (
    <div>
      <div key={index} className="flex p-2 pt-0">
        <div
          className="flex w-1/2 cursor-pointer flex-col items-center justify-center p-2 pt-0"
          onClick={onClick}
        >
          <Img
            imgPath={`${productId}/genga/${index}.png`}
            alt="原画画像"
            className="mb-2 h-auto w-full shadow-md"
          />
        </div>
        <div className="flex w-1/2 cursor-pointer flex-col items-center justify-center p-2 pt-0">
          {isEnd && (
            <>
              {canIncrease ? (
                <CircleButton
                  title="＋"
                  backgroundColor="#f54254"
                  onClick={() => updateTicketCount?.(1, -1)}
                />
              ) : (
                <DisabledCircleButton title="＋" />
              )}
              <Space height={8} />
            </>
          )}
          <p>{applyCount}枚</p>
          {isEnd && (
            <>
              <Space height={8} />
              {canDecrease ? (
                <CircleButton
                  title="ー"
                  backgroundColor="#0bb4e3"
                  textColor="#fff"
                  onClick={() => updateTicketCount?.(-1, 1)}
                />
              ) : (
                <DisabledCircleButton title="ー" />
              )}
            </>
          )}
        </div>
      </div>
      <p>{displayName}</p>
      <Space height={16} />
    </div>
  );
};
