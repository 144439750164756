import { memo } from "react";

type Props = {
  children: React.ReactNode;
};

export const Fukidashi = memo(({ children }: Props) => (
  <div className="absolute bottom-[calc(100%+16px)] left-1/2 flex h-12 w-full max-w-[280px] -translate-x-1/2 flex-col items-center justify-center rounded-lg bg-[#00b8ea] text-xl font-bold text-white">
    {children}
    <div className="absolute bottom-[-9px] left-1/2 size-0 -translate-x-1/2 border-x-[10px] border-t-[10px] border-x-transparent border-t-[#00b8ea]"></div>
  </div>
));
