export const KUJI_TYPE = {
  Normal: "Normal",
  S: "S",
} as const;

export type KujiType = "Normal" | "S";

export type CutInfoType = {
  index: number;
  displayName: string;
  animator: string[];
  character: string[];
  cutId: string;
  productId: string;
  type: KujiType;
  mixedCount: number;
  rawCount: number;
};
