import { Timestamp } from "firebase/firestore";
import { memo } from "react";

import { convertFormattedDate } from "../utils/index";

import { Space } from ".//Space";

type Props = {
  title: string;
  count: number;
  date: Timestamp;
};

export const HistoryItem = memo(({ title, count, date }: Props) => {
  return (
    <div className="my-6 flex w-full max-w-[720px] cursor-pointer items-center rounded-lg border border-[#cdcdcd] bg-white shadow-md">
      <div className="flex flex-col items-start p-4">
        <p className="text-base text-black">{title}</p>
        <Space height={8} />
        <p className="text-sm text-black">{count}回</p>
        <Space height={8} />
        <p className="text-xs text-[#999999]">{convertFormattedDate(date)}</p>
      </div>
    </div>
  );
});
