import { memo } from "react";

type Props = {
  children: React.ReactNode;
  color?: "red" | "blue";
  onClick?: () => void;
};

export const ColorSpan = memo(({ children, color = "red", onClick }: Props) => (
  <span
    className={`cursor-pointer text-sm font-medium ${
      color === "red"
        ? "text-red hover:border-red"
        : "text-blue hover:border-blue"
    } hover:border-b`}
    onClick={onClick}
  >
    {children}
  </span>
));
