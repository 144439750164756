export const getDayOfWeek = (date: Date): string => {
  const days = ["日", "月", "火", "水", "木", "金", "土"];
  return days[date.getDay()];
};

export const formatDate = (date: Date): string => {
  return `${date.getFullYear()}/${
    date.getMonth() + 1
  }/${date.getDate()}（${getDayOfWeek(date)}）${date.getHours()}:${date
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;
};
