import { ProductType } from "@gengakuji/common";
import { memo } from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { formatDate } from "../utils/index";

import { BaseButton } from "./Button/BaseButton";
import { DisabledButton } from "./Button/DisabledButton";
import { YubiButton } from "./Button/YubiButton";
import { Fukidashi } from "./Fukidashi";
import { XShareButton } from "./Share";

interface Props {
  user: any;
  productData: ProductType | null;
  start: Date;
  end: Date;
  firstBuy: boolean;
  handleBuyKuji: (drawNumber: number) => void;
}

const now = new Date();

// カウントダウン
const calculateTimeLeft = (start: Date, end: Date) => {
  const now = new Date();
  let timeLeft = 0;

  if (now <= start) {
    //販売開始前
    timeLeft = start.getTime() - now.getTime();
  } else if (
    now < end &&
    new Date(end.getTime() - 7 * 24 * 60 * 60 * 1000) < now
  ) {
    //販売終了から7日前から販売終了まで
    timeLeft = end.getTime() - now.getTime();
  } else {
    return null;
  }

  const daysCount = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hoursCount = Math.floor(
    (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutesCount = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const secondsCount = Math.floor((timeLeft % (1000 * 60)) / 1000);

  return { daysCount, hoursCount, minutesCount, secondsCount };
};

export const FixedBottom = memo(
  ({ user, productData, start, end, firstBuy, handleBuyKuji }: Props) => {
    const [isLoadingOne, setIsLoadingOne] = useState(false);
    const [isLoadingTen, setIsLoadingTen] = useState(false);
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(start, end));
    const formattedStart = formatDate(start);
    const baseEventName = window.location.pathname + window.location.search;
    const eventNameSuffix = firstBuy ? "【初回】" : "";
    const eventName1 = `${eventNameSuffix}くじを1回引く${baseEventName}`;
    const eventName10 = `${eventNameSuffix}くじを10回引く${baseEventName}`;

    // カウントダウン計算
    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(start, end));
      }, 1000);

      return () => clearInterval(timer);
    }, [start, end]);

    // くじ購入処理
    const handleOnClick = async (drawNumber: number) => {
      const setIsLoading = drawNumber === 1 ? setIsLoadingOne : setIsLoadingTen;

      try {
        setIsLoading(true);
        await handleBuyKuji(drawNumber);
      } catch (error) {
        console.error("くじ購入に失敗しました", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!productData) {
      return null;
    }

    return (
      <div className="fixed bottom-4 z-[999] flex w-full max-w-[calc(100vw-32px)] justify-center min-[722px]:max-w-[690px]">
        {/* 販売前 */}
        {now < start && (
          <>
            <Fukidashi>
              <span className="text-xs">販売開始まで</span>
              <p>
                {timeLeft?.daysCount}
                <span className="mx-1 text-xs">日</span>
                {timeLeft?.hoursCount}
                <span className="mx-1 text-xs">時間</span>
                {timeLeft?.minutesCount}
                <span className="mx-1 text-xs">分</span>
                {timeLeft?.secondsCount}
                <span className="mx-1 text-xs">秒</span>
              </p>
            </Fukidashi>
            {user ? (
              <XShareButton
                url={`https://gengakuji.com/${productData?.productId || ""}`}
                text={`${
                  productData?.productName ?? ""
                }に事前登録しました！ 【${formattedStart}】より販売開始！`}
                hashtags={
                  productData && productData.hashtags
                    ? [...productData.hashtags, "原画くじ"]
                    : ["原画くじ"]
                }
                related={["gengakuji"]}
                via="gengakuji"
              >
                <BaseButton title="Xにシェア" />
              </XShareButton>
            ) : (
              <Link
                to={`/register?from=${productData?.productId}`}
                className="flex-1"
              >
                <BaseButton title="事前登録する" />
              </Link>
            )}
          </>
        )}
        {/* 販売中 */}
        {now >= start && now <= end && (
          <>
            {(firstBuy || !user) &&
              productData &&
              (productData.initialDiscountRate > 0 ? (
                <Fukidashi>
                  <p>初回限定{productData.initialDiscountRate}%OFF！</p>
                </Fukidashi>
              ) : (
                !productData.isDuplicate && (
                  <Fukidashi>
                    <p>10連で被りなし！</p>
                  </Fukidashi>
                )
              ))}
            {user ? (
              <>
                {productData && !productData.isDuplicate && (
                  <Fukidashi>
                    <p>10連で被りなし！</p>
                  </Fukidashi>
                )}
                {timeLeft && (
                  <Fukidashi>
                    <span className="text-xs">販売終了まで</span>
                    <p>
                      {timeLeft.daysCount}
                      <span>日</span>
                      {timeLeft.hoursCount}
                      <span>時間</span>
                      {timeLeft.minutesCount}
                      <span>分</span>
                      {timeLeft.secondsCount}
                      <span>秒</span>
                    </p>
                  </Fukidashi>
                )}
                <div className="flex w-full justify-center">
                  <div className="mr-1 flex-1">
                    <BaseButton
                      onClick={() => handleOnClick(1)}
                      title="1回引く"
                      isLoading={isLoadingOne}
                      gaEvent={eventName1}
                    />
                  </div>
                  <div className="ml-1 flex-1">
                    <YubiButton
                      onClick={() => handleOnClick(10)}
                      title="10連を引く"
                      isLoading={isLoadingTen}
                      gaEvent={eventName10}
                    />
                  </div>
                </div>
              </>
            ) : (
              <Link
                to={`/register?from=${productData?.productId}`}
                className="flex-1"
              >
                <BaseButton title="登録・ログインしてくじを引く" />
              </Link>
            )}
          </>
        )}
        {/* 販売終了 */}
        {now > end && (
          <>
            <DisabledButton title="販売終了" />
          </>
        )}
      </div>
    );
  }
);
