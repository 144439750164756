import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { BaseWhiteBg } from "../components/BaseWhiteBg";
import { Border } from "../components/Border";
import { BaseButton } from "../components/Button/BaseButton";
import { DisabledButton } from "../components/Button/DisabledButton";
import { GoogleButton } from "../components/Button/GoogleButton";
import { ColorSpan } from "../components/ColorSpan";
import { H2 } from "../components/H2";
import { Input } from "../components/Input";
import { Space } from "../components/Space";
import { auth } from "../firebase";
import { useAuthUser } from "../hooks/useAuth";
import { handleEmailChange, handlePasswordChange } from "../utils";

const title = "新規登録";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectPath = searchParams.get("from");
  const navigate = useNavigate();

  const user = useAuthUser();

  const isFormValid = !emailError && !passwordError && email && password;

  //登録済みのユーザーか判断
  useEffect(() => {
    const fetchUser = async () => {
      if (user != null) {
        //既に登録済みなら、Topへリダイレクトする
        navigate(redirectPath ? `/${redirectPath}` : "/");
      }
    };
    fetchUser();
  }, [user, navigate]);

  // メールアドレスとパスワードで登録
  const handleSubmit = async () => {
    setIsLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        navigate(redirectPath ? `/${redirectPath}` : "/");
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/email-already-in-use":
            setEmailError("既に登録されているメールアドレスです。");
            break;
          case "auth/invalid-email":
            setEmailError("無効なメールアドレス形式です。");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Googleで登録
  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate(redirectPath ? `/${redirectPath}` : "/");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <BaseWhiteBg>
        <H2 text={title} blue={3} />
        <Input
          type="email"
          placeholder="メールアドレス"
          value={email}
          onChange={(e) => handleEmailChange(e, setEmail, setEmailError)}
          errorMessage={emailError}
        />
        <Space height={24} />
        <Input
          type="password"
          placeholder="パスワード"
          value={password}
          onChange={(e) =>
            handlePasswordChange(e, setPassword, setPasswordError)
          }
          errorMessage={passwordError}
        />
        <Space height={24} />
        {isFormValid ? (
          <BaseButton
            title="メールアドレスで登録"
            backgroundColor="#ff4127"
            textColor="#fff"
            onClick={handleSubmit}
            isLoading={isLoading}
          />
        ) : (
          <DisabledButton title="メールアドレスで登録" />
        )}
        <Space height={48} />
        <Border color="#cacaca" />
        <Space height={48} />
        <GoogleButton onClick={signInWithGoogle} title="Googleで登録" />
        <Space height={24} />
        <p className="text-sm">
          登録することで
          <a
            href="https://avaren.co.jp/gengakuji-terms"
            target="_blank"
            rel="noreferrer"
          >
            <ColorSpan color="blue">利用規約</ColorSpan>
          </a>
          と
          <a
            href="https://avaren.co.jp/gengakuji-privacy"
            target="_blank"
            rel="noreferrer"
          >
            <ColorSpan color="blue">プライバシーポリシー</ColorSpan>
          </a>
          に同意したとみなされます。
        </p>
        <Space height={48} />
        <Link to={redirectPath ? `/login?from=${redirectPath}` : "/login"}>
          <ColorSpan>アカウントをお持ちの方はこちら</ColorSpan>
        </Link>
        <Space height={48} />
      </BaseWhiteBg>
    </>
  );
};

export default Register;
