import { CutInfoType, KujiType } from "@gengakuji/common";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import { FirestoreDocument } from "../constants";
import { db } from "../firebase";

export const getCutInfo: (
  productId: string,
  cutId: string
) => Promise<CutInfoType> = async (productId: string, cutId: string) => {
  const cutInfoRef = doc(db, "cut_info", productId, "cuts", cutId);
  const cutInfoDoc = (await getDoc(cutInfoRef)).data() as CutInfoType;
  return cutInfoDoc;
};

export const getTypedCutInfo: (
  productId: string,
  kujiType: KujiType
) => Promise<CutInfoType[]> = async (productId: string, kujiType: KujiType) => {
  const cutInfoCollection = collection(db, "cut_info", productId, "cuts");
  const q = query(cutInfoCollection, where("type", "==", kujiType));
  const qs = await getDocs(q);

  const cutInfoPromise = qs.docs.map(async (doc) => {
    return doc.data() as CutInfoType;
  });

  let cutInfo = await Promise.all(cutInfoPromise);
  // ここで `index` に基づいて昇順にソート
  cutInfo = cutInfo.sort((a, b) => a.index - b.index);
  return cutInfo;
};

export const getCutInfos: (
  productId: string
) => Promise<FirestoreDocument<CutInfoType>[]> = async (productId: string) => {
  const cutInfoCollection = collection(db, "cut_info", productId, "cuts");
  const qs = await getDocs(cutInfoCollection);

  const cutInfoPromise = qs.docs.map(async (doc) => {
    return { ...(doc.data() as CutInfoType), id: doc.id };
  });

  let cutInfo = await Promise.all(cutInfoPromise);
  // ここで `index` に基づいて昇順にソート
  cutInfo = cutInfo.sort((a, b) => a.index - b.index);
  return cutInfo;
};
