import { memo, ReactNode } from "react";

import { Space } from "../Space";

type Props = {
  children: ReactNode;
};

export const BaseTable = memo(({ children }: Props) => (
  <div className="mx-[-16px] w-[calc(100%+32px)] bg-white/20 px-6 shadow-md">
    <Space height={48} />
    <table>
      <tbody>{children} </tbody>
    </table>
    <Space height={48} />
  </div>
));
