import { Timestamp } from "firebase/firestore";

export const convertToTimestamp = (
  year: number,
  month: number,
  day: number
) => {
  const date = new Date(year, month - 1, day);
  return Timestamp.fromDate(date);
};
