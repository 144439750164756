import { Timestamp } from "firebase/firestore";

export const convertTimeStampToDate = (timestamp: any) => {
  let date: Date;
  if (typeof timestamp.toDate === "function") {
    date = timestamp.toDate();
  } else {
    if (timestamp.seconds) {
      date = new Date(
        timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
      );
    } else {
      date = new Date(
        timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
      );
    }
  }
  return date;
};

export const convertDateToDays: (date: Date) => {
  year: number;
  month: number;
  day: number;
  hours: number;
  minutes: number;
  dayOfWeek: number;
  dayOfWeekJPN: string;
} = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const daysInJapanese = ["日", "月", "火", "水", "木", "金", "土"];
  const dayOfWeek = date.getDay();
  const dayOfWeekJPN = daysInJapanese[dayOfWeek];

  return { year, month, day, hours, minutes, dayOfWeek, dayOfWeekJPN };
};

export const convertFormattedDate = (timestamp: Timestamp) => {
  const date = convertTimeStampToDate(timestamp);
  const days = convertDateToDays(date);

  const formattedDate = `${days.year}/${days.month}/${days.day}（${
    days.dayOfWeekJPN
  }）${days.hours}:${days.minutes.toString().padStart(2, "0")}`;

  return formattedDate;
};
