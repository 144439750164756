import { memo } from "react";

interface Props {
  imgPath: string;
  alt: string;
  width?: string;
  className?: string;
}

export const Img = memo(
  ({ imgPath, alt, width = "100%", className }: Props) => {
    const bucketUrl = `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET}`;
    const src = `${bucketUrl}/${imgPath}`;

    return <img src={src} alt={alt} width={width} className={className} />;
  }
);
