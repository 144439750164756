import { memo } from "react";

type Props = {
  title: string;
};

export const DisabledCircleButton = memo(({ title }: Props) => {
  return (
    <button className="size-[48px] cursor-auto rounded-full border-none bg-[#f0f0f0] text-base font-bold text-[#757373]">
      {title}
    </button>
  );
});
