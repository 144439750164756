import { logEvent } from "firebase/analytics";
import { memo, ReactNode } from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
} from "react-share";

import X from "../assets/icon/x-logo.webp";
import { analytics } from "../firebase";

import { Space } from "./Space";

type XProps = {
  url: string;
  text: string;
  hashtags?: string[];
  via?: string;
  related?: string[];
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
};

export const XShareButton = memo(
  ({
    url,
    text,
    hashtags = [],
    via,
    related = [],
    children,
    className,
    onClick,
  }: XProps) => {
    // Twitter共有URLの生成
    const createXShareUrl = (): string => {
      // 配列をカンマ区切りの文字列に変換
      const hashtagsString = hashtags.join(",");
      const relatedString = related.join(",");

      const params = new URLSearchParams({
        url,
        text,
        ...(hashtags.length > 0 && { hashtags: hashtagsString }), // 配列が空でない場合のみパラメータを追加
        ...(via && { via }), // viaがundefinedでない場合のみパラメータを追加
        ...(related.length > 0 && { related: relatedString }), // 配列が空でない場合のみパラメータを追加
      });

      return `https://twitter.com/intent/tweet?${params.toString()}`;
    };

    return (
      <button
        className={className}
        onClick={() => {
          if (onClick) onClick();
          window.open(createXShareUrl(), "_blank");
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          backgroundColor: "transparent",
          border: "none",
        }}
      >
        {children ? children : "Share on Twitter"}
      </button>
    );
  }
);

interface ShareProps {
  url: string;
  text: string;
  hashtags?: string[];
}

export const Share = memo(({ url, text, hashtags }: ShareProps) => {
  const trackShareEvent = (platform: string) => {
    const eventName = `${platform}にシェア${
      window.location.pathname + window.location.search
    }`;
    logEvent(analytics, eventName);
  };

  return (
    <>
      <h3>シェア</h3>
      <Space height={36} />
      <div className="flex h-12">
        <XShareButton
          url={url}
          text={text}
          hashtags={hashtags}
          related={["@gengakuji"]}
          via="gengakuji"
          className="flex h-12 items-center justify-center"
          onClick={() => trackShareEvent("X")}
        >
          <div className="flex size-12 items-center justify-center rounded-full bg-black">
            <img src={X} width={24} alt="Xのロゴ" />
          </div>
        </XShareButton>
        <LineShareButton
          url={url}
          title={text}
          className="flex h-12 items-center justify-center"
          onClick={() => trackShareEvent("LINE")}
        >
          <LineIcon size={48} round />
        </LineShareButton>
        <FacebookShareButton
          url={url}
          title={text}
          className="flex h-12 items-center justify-center"
          onClick={() => trackShareEvent("Facebook")}
        >
          <FacebookIcon size={48} round />
        </FacebookShareButton>
      </div>
    </>
  );
});
