import { memo } from "react";

import { Space } from "./Space";

interface Props {
  text: string;
  blue?: number;
}

export const H2 = memo(({ text, blue }: Props) => {
  const getCharColor = (index: number) => {
    if (index === 1) return "#ff565f";
    if (blue !== undefined && index === blue) return "#15c0ed";
    return "#1a1a1a";
  };

  return (
    <h2>
      <Space height={36} />
      {text.split("").map((char, index) => (
        <span
          key={index}
          style={{
            color: getCharColor(index),
            fontWeight: "700",
          }}
        >
          {char}
        </span>
      ))}
      <Space height={36} />
    </h2>
  );
});
