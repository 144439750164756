import { memo } from "react";

type Props = {
  children: React.ReactNode;
};

export const BaseCard = memo(({ children }: Props) => (
  <div className="my-6 max-w-[calc(720px-32px)] rounded-2xl border border-[#cdcdcd] bg-white px-6 shadow-md">
    {children}
  </div>
));
