import { ProductType, CutInfoType, FirestoreDocument, CollectionType } from "@gengakuji/common";
import { memo, useState } from "react";

import check_icon from "../assets/icon/check.webp";
import normalTitle from "../assets/titile/normal-title.webp";
import specialTitle from "../assets/titile/special-title.webp";

import { Img } from "./Img";
import { CutModal } from "./Modal/CutModal";
import { Space } from "./Space";

interface Props {
  title: string;
  count: number;
  rate: number;
  alt: string;
  className: string;
  cutInfo: CutInfoType[] | null;
  productData: ProductType | null;
  collections: FirestoreDocument<CollectionType>[]
}

export const Prize = memo(
  ({ title, count, rate, className, cutInfo, productData, collections }: Props) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedCutInfo, setSelectedCutInfo] = useState<CutInfoType | null>(
      null
    );
    const [selectedProductData, setSelectedProductData] =
      useState<ProductType | null>(null);

    //原画画像クリック時の処理
    const handleImgClick = (cutInfo: CutInfoType, productData: ProductType) => {
      setSelectedCutInfo(cutInfo);
      setSelectedProductData(productData);
      setShowModal(true);
    };

    const getTitleStyle = (title: string) => {
      if (title === "S賞") {
        return {
          backgroundImage: `url(${specialTitle})`,
        };
      } else if (title === "通常賞") {
        return {
          backgroundImage: `url(${normalTitle})`,
        };
      } else {
        return {};
      }
    };

    // 当選済みか判定する関数
    const isWon = (cutInfo: CutInfoType) => {
      let result = collections.some((collection) => collection.cutInfo.index === cutInfo.index)
      return result;
    };

    return (
      <>
        <div className="flex items-center justify-center">
          <h2
            className="flex h-[60px] w-[133.125px] items-center justify-center bg-cover bg-center"
            style={getTitleStyle(title)}
          >
            {title}
          </h2>
        </div>
        <Space height={16} />
        <div className="flex items-center justify-between">
          <h3>{`全${count}種`}</h3>
          <h3>{`当選確率${rate}%`}</h3>
        </div>
        <Space height={16} />
        <div className={className}>
          {cutInfo &&
            cutInfo.map((val) => (
              <div key={val.displayName}>
                <div
                  className={`cursor-pointer shadow-md ${isWon(val) ? 'relative inline-block' : ''}`}
                  onClick={() =>
                    productData && handleImgClick(val, productData)
                  }
                >
                {isWon(val) && (
                  <>
                    <img
                      src={check_icon}
                      alt="チェックアイコン"
                      className="absolute bottom-2 right-2 z-[102] w-[36px]"
                    />
                  </>
                )}
                  <Img
                    imgPath={`${val.productId}/cover/${val.index}.png`}
                    alt="原画画像"
                    className="w-full"
                  />
                </div>
                <Space height={16} />
                <p>{val.displayName}</p>
              </div>
            ))}
        </div>
        {showModal && selectedCutInfo && (
          <CutModal
            cutInfo={selectedCutInfo}
            productData={selectedProductData}
            onClose={() => setShowModal(false)}
          />
        )}
      </>
    );
  }
);
