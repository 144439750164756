import { memo } from "react";

type Props = {
  children: React.ReactNode;
  id: string;
  value: number;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const Select = memo(({ children, id, value, onChange }: Props) => (
  <select
    id={id}
    value={value}
    onChange={onChange}
    className="m-0 mr-2 h-10 border border-[#cacaca] bg-white p-2 text-[16px] text-black focus:shadow-[0_0_2px_#cacaca] focus:outline-none"
  >
    {children}
  </select>
));
