import { RequestedShipInfo } from "@gengakuji/common";
import { memo } from "react";
import { useNavigate } from "react-router-dom";

import { convertFormattedDate, formatZipCode } from "../../utils";
import { BaseButton } from "../Button/BaseButton";
import { Img } from "../Img";
import { Space } from "../Space";

import { BaseCard } from "./BaseCard";

interface Props {
  requestedShipInfo: RequestedShipInfo;
}

export const RequestedCard = memo(({ requestedShipInfo }: Props) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    const customReceiptNumber = requestedShipInfo.shipInfo.customReceiptNumber;
    navigate(`/shipping/${customReceiptNumber}`);
  };

  return (
    <BaseCard>
      <Space height={16} />
      <>
        <Img
          imgPath={`${requestedShipInfo.productData.productId}/ogp.png`}
          alt="商品画像"
        />
        <Space height={16} />
        <h3>{requestedShipInfo.productData.productName}</h3>
        <Space height={8} />
        <p>全{requestedShipInfo.shipInfo.numOfAvailableShip}点</p>
      </>
      <Space height={16} />
      <div className="text-left">
        <>
          <label>配送依頼日時</label>
          <p>{convertFormattedDate(requestedShipInfo.shipInfo.createdAt)}</p>
        </>
        <Space height={16} />
        <>
          <label>発送予定時期</label>
          <p>{requestedShipInfo.shipInfo.FinalShippingDate}</p>
        </>
        <Space height={16} />
        <>
          <label>配送管理番号</label>
          <p> {requestedShipInfo.shipInfo.customReceiptNumber}</p>
        </>
      </div>
      <Space height={16} />
      <BaseButton onClick={handleNavigate} title="詳細を見る" />
      <Space height={16} />
    </BaseCard>
  );
});
