import { getStorage, ref, getDownloadURL } from "firebase/storage";

const storage = getStorage();

export const getParapara = async (
  productId: string,
  cutId: string,
  mixedCount: number
): Promise<string[]> => {
  const imagePaths = Array.from(
    { length: mixedCount },
    (_, i) => `${productId}/parapara/${cutId}/${i}.png`
  );

  const imageRefs = imagePaths.map((path) => ref(storage, path));

  const imagePromises = imageRefs.map((imageRef) =>
    getDownloadURL(imageRef).catch((error) => {
      console.error("画像の取得に失敗しました:", error);
      return ""; // 失敗した場合は空の文字列を返す
    })
  );

  // Promise.allを使用して、すべての画像のURLを並行して取得
  const images = await Promise.all(imagePromises);
  return images.filter((url) => url !== "");
};
