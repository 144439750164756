import { auth } from "../firebase";

export const getUserIdToken = async () => {
  const idToken = await auth.currentUser?.getIdToken(true);
  if (idToken) {
    return idToken;
  } else {
    return null;
  }
};
