import { memo } from "react";

interface TabItemProps {
  isActive: boolean;
  onClick: () => void;
  label: string;
}

const TabItem = memo(({ isActive, onClick, label }: TabItemProps) => {
  return (
    <div
      className={`w-full cursor-pointer border-b border-[#cdcdcd] bg-white p-2 text-sm ${
        isActive ? "border-b-4 border-[#ff565f] text-black" : "text-[#999]"
      }`}
      onClick={onClick}
    >
      {label}
    </div>
  );
});

interface Props {
  activeTab: string;
  onChangeTab: (tab: string) => void;
}

export const Tab = memo(({ activeTab, onChangeTab }: Props) => {
  return (
    <div className="flex justify-center">
      <TabItem
        isActive={activeTab === "request"}
        onClick={() => onChangeTab("request")}
        label="配送依頼可能"
      />
      <TabItem
        isActive={activeTab === "requested"}
        onClick={() => onChangeTab("requested")}
        label="配送依頼済み"
      />
      <TabItem
        isActive={activeTab === "shipped"}
        onClick={() => onChangeTab("shipped")}
        label="発送済み"
      />
    </div>
  );
});
