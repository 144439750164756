import { logEvent } from "firebase/analytics";
import { memo } from "react";

import { analytics } from "../../firebase";
import { Loading } from "../Loading";

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon?: string;
  title: string;
  backgroundColor?: string;
  textColor?: string;
  width?: string;
  border?: string;
  isLoading?: boolean;
  gaEvent?: string;
}

export const YubiButton = memo(
  ({
    onClick,
    title,
    backgroundColor = "#ff4127",
    textColor = "#fff",
    width = "100%",
    border,
    isLoading = false,
    icon = require("../../assets/icon/yubi.webp"),
    gaEvent = `${title}${window.location.pathname + window.location.search}`,
  }: Props) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(e);
      }

      if (gaEvent) {
        logEvent(analytics, gaEvent);
      }
    };
    return (
      <button
        className={`relative h-[52px] max-w-[320px] cursor-pointer rounded-full border border-b-[6px] border-black text-center text-sm font-bold ${
          isLoading ? "cursor-not-allowed" : ""
        }`}
        onClick={onClick}
        style={{ backgroundColor, color: textColor, width, border }}
        disabled={isLoading}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <img
              src={icon}
              alt="icon"
              className="absolute right-6 top-1/2 size-[58px] -translate-y-1/2"
            />
            <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 font-bold">
              {title}
            </span>
          </>
        )}
      </button>
    );
  }
);
