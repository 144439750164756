import { Timestamp } from "firebase/firestore";

export const SALE_STATUS = {
  BEFORE_SALE: 0,
  NOW_ON_SALE: 1,
  AFTER_SALE: 2,
} as const;

export type SaleStatus = 0 | 1 | 2;

// ProductType 型の定義
export type ProductType = {
  productId: string;
  productName: string;
  anime: string;
  kujiTitle: string;
  count: number;
  productTypes: {
    SType?: string[];
    NormalType?: string[];
  };
  rate: {
    SType: number;
    NormalType: number;
  };
  price1: number;
  price10: number;
  initialDiscountRate: number;
  priceIds: {
    postage: string;
    discountOne: string;
    discountTen: string;
    one: string;
    ten: string;
  };
  open: Timestamp;
  start: Timestamp;
  end: Timestamp;
  shippingRequestDeadline: Timestamp[];
  scheduledShippingAt: string[];
  postage: number;
  payment: string[];
  copyright: string;
  company: string[];
  hashtags: string[];
  productSlides: string[];
  wchance: {
    isOpen: boolean;
    prize: string;
    applyDeadline: Timestamp;
    scheduledShippingAt: string;
    isWon: boolean;
  };
  kujiVideo?: {
    S: string;
    Normal: string;
  };
  isDuplicate: boolean;
};
