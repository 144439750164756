import { memo } from "react";
import { Virtuoso } from "react-virtuoso";

interface Props {
  images: string[];
}

export const Parapara = memo(({ images }: Props) => {
  // 上下レンダリング範囲の制御（ピクセル）
  const increaseViewportBy = 5000;

  return (
    <>
      <style>
        {`
          .window {
            height: 70.6vh;
            clip-path: inset(0);
          }

          @media screen and (min-width: 720px) {
            .window {
              height: 508px;
              /* 原画画像が720*508=100:70.6 */
            }
          }
        `}
      </style>
      <Virtuoso
        useWindowScroll
        data={images}
        increaseViewportBy={increaseViewportBy}
        itemContent={(_, image) => (
          <>
            <div className="window">
              <div
                className="fixed left-1/2 top-1/2 size-full -translate-x-1/2 -translate-y-1/2 bg-transparent bg-[length:calc(100vw-32px)] bg-center bg-no-repeat md:bg-[length:720px]"
                style={{ backgroundImage: `url(${image})` }}
              ></div>
            </div>
            <div className="h-2 w-full"></div>
          </>
        )}
      />
    </>
  );
});
