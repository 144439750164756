import { memo } from "react";

interface Props {
  title?: string;
}

export const DisabledButton = memo(({ title }: Props) => {
  return (
    <button
      className="h-[52px] w-full max-w-[320px] cursor-auto rounded-full border-none bg-[#f0f0f0] text-center text-sm font-bold text-[#757373]"
      disabled
    >
      {title}
    </button>
  );
});
