type Props = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  title: string;
  backgroundColor: string;
  textColor?: string;
  borderColor?: string;
};

export const CircleButton = ({
  title,
  backgroundColor,
  onClick,
  textColor,
  borderColor = "black",
}: Props) => {
  return (
    <button
      style={{
        backgroundColor,
        color: textColor,
        border: `1px solid ${borderColor}`,
        borderBottom: `2px solid ${borderColor}`,
      }}
      className="size-[48px] cursor-pointer rounded-full border-none bg-white text-base font-bold text-black"
      onClick={onClick}
    >
      {title}
    </button>
  );
};
